var buildCartUrlAndRedirect = function buildCartUrlAndRedirect(cartRedirect, publish) {
  return function (_ref) {
    var _ref$cartInfo = _ref.cartInfo,
      cartInfo = _ref$cartInfo === void 0 ? {} : _ref$cartInfo,
      _ref$channel = _ref.channel,
      channel = _ref$channel === void 0 ? '' : _ref$channel,
      clientId = _ref.clientId,
      error = _ref.error,
      location = _ref.location,
      product = _ref.product,
      services = _ref.services,
      typeButton = _ref.typeButton;
    if (!error) {
      var cartId = cartInfo.cartId,
        _cartInfo$cartUrl = cartInfo.cartUrl,
        cartUrl = _cartInfo$cartUrl === void 0 ? {} : _cartInfo$cartUrl;
      publish('addtocart:success', {
        cartId: cartId,
        channel: channel,
        clientId: clientId,
        location: location,
        product: product,
        services: services,
        typeButton: typeButton
      });
      cartRedirect({
        cartUrl: cartUrl,
        type: typeButton
      });
    } else {
      publish('addtocart:error', {
        error: error,
        product: product
      });
    }
  };
};
export default buildCartUrlAndRedirect;